<template>

    <div class="section-content pt-4" :class="{ 'mt-10': $vuetify.breakpoint.xs, 'mt-n3': $vuetify.breakpoint.sm }">
        <div class="primary-section-content">
            <div>
                <HbEmptyState message="Please upload the payroll file to continue." btn-txt="Upload"
                    @click="uploadDocument = true">

                    <HbBtn @click="downloadTemplate" icon tooltip="Downloads Previously uploaded payroll file/Sample payroll file (If no previously uploaded file found)" mdi-code="mdi-download" />
                    <hb-link @click="downloadTemplate" tooltip = "Downloads Previously uploaded payroll file/Sample payroll file (If no previously uploaded file found)">Download Previous Payroll</hb-link>
                </HbEmptyState>
            </div>
            <upload-file v-model="uploadDocument" v-if="uploadDocument" type="file" model="payroll" size="lg"
                slot="body" @close="uploadDocument = false" @save="completeUpload">
            </upload-file>
            <hb-global-notification v-model="showNotification" type="success"
                description="Payroll uploaded successfully.">
            </hb-global-notification>
        </div>
    </div>
</template>

<script type="text/babel">
    import HbDatePicker from '../assets/HummingbirdDatepicker';
    import GetFilter from './Getfilter.vue'; 
    import api from '../../assets/api.js';
    import UploadFile from '../assets/UploadFile.vue';
    import { notificationMixin } from '../../mixins/notificationMixin';
    export default {
        name: "FlaggedAccounts",
        data() {
            return {
                todaysDate: '',
                showMasterFilterModal: false,
                loadingDocuments: false,
                uploadDocument: false,
                property: '',
                items: [],
                success: false,
                infinityLoader: false,
                currentPage: 0,
                period: '',
                totalPages: 0,
                showNotification: false,
                payment_id:''
            }
        },
        components:{
            HbDatePicker,
            GetFilter,
            UploadFile
        },
        mounted() {
        },
     async created (){
       
        },
        computed:{
          
        },
        mixins: [notificationMixin],
        methods:{
            async completeUpload(){
                this.showNotification = true;
                console.log("Upload complete");
            },
            async downloadTemplate() {
                this.showMessageNotification({
                    type: 'document_download', 
                    title: 'Sample Template Download',
                    loading: true,
                    timeout: 5000,
                    description: 'We are generating your document, it should begin downloading shortly.'
                });

                try {
                    let response = await api.get(
                    this,
                    api.DOWNLOAD_PAYROLL_TEMPLATE,
                    );
                    var buffer = new Uint8Array(response.data);
                    var blob = new Blob([buffer], { type: "text/csv" });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'sample_payroll.csv';
                    link.click();
                } catch (err) {
                    this.showMessageNotification({ description: err })
                } finally {
                    this.downloadButton = false;
                }
    }
        },
        watch: {
            
        }
    }


</script>

<style lang="scss" scoped>
.section-content {
    .header-class {
        display: flex;
        justify-content: space-between;
    }

    .main-wraper-container {
        display: flex;
        justify-content: space-between;
        height: calc(100vh - 73px);
        height: calc(100svh - 73px);
        overflow: hidden;
    }

    .v-navigation-drawer__content a.v-list-item:not(.v-list-item--active) {
        background-color: #f9fafb !important;
        border-left: 4px solid #f9fafb;
    }

    .v-navigation-drawer__content a.v-list-item--active {
        background-color: #e2f2f3 !important;
        border-left: 4px solid #00848e;
    }
}
</style>